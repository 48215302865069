import "../global";
import "./style.scss";
import { auth } from "../global";
import { EmailAuthProvider } from "firebase/auth";
import * as firebaseui from "firebaseui";

const appDiv = document.querySelector(".entry");
const authDiv = document.createElement("div");
authDiv.id = "firebaseui-auth-container";
appDiv?.append(authDiv);

//check auth
auth.onAuthStateChanged(function (user) {
  if (user) {
    // User is signed in.
    window.location.href = "/intern";
  } else {
    // No user is signed in.
  }
});

const ui = new firebaseui.auth.AuthUI(auth);
ui.start("#firebaseui-auth-container", {
  signInOptions: [EmailAuthProvider.PROVIDER_ID],
  callbacks: {
    signInSuccessWithAuthResult: (/* authResult, redirectUrl */) => {
      if (window.self !== window.top) {
        // checking if it is an iframe
      } else {
        //window.location.href = websiteUrl;
      }

      return true;
    },
  },
});
